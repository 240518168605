<template>
  <el-dialog
    :title="lang_data == 1 ? '重置密码' : 'Reset password'"
    :visible="pwdStatusShow"
    :before-close="cancel"
    width="500px"
    :close-on-click-modal="false"
  >
    <div class="mwCont">
      <div v-if="step == 1" class="modalCont">
        <el-form
          ref="formvalue"
          @submit.native.prevent
          :model="formvalue"
          :rules="Rules"
        >
          <div class="step">
            <div class="step1">
              <a class="act">{{
                lang_data == 1 ? "验证身份" : "Authenticate"
              }}</a
              ><a>{{
                lang_data == 1 ? "重置密码" : "To reset your password"
              }}</a>
            </div>
            <div class="step2"><a class="act">&nbsp;</a><a>&nbsp;</a></div>
          </div>
          <div class="mt10">
            <el-radio-group v-model="radio">
              <el-radio :label="1" :disabled="!doctor_info.mobile">{{
                lang_data == 1 ? "手机号" : "Mobile"
              }}</el-radio>
              <el-radio :label="2" :disabled="!doctor_info.email">{{
                lang_data == 1 ? "邮箱" : "Email"
              }}</el-radio>
            </el-radio-group>
          </div>

          <div class="mt20 mb10">
            {{ lang_data == 1 ? "账号：" : "Account "
            }}<label>{{
              radio == 1 ? doctor_info.mobile : doctor_info.email
            }}</label>
          </div>

          <el-form-item label="" prop="pwdCode" class="flex-1">
            <el-input
              v-model="formvalue.pwdCode"
              style="width: 220px"
              size="small"
              type="text"
              :placeholder="
                lang_data == 1 ? '请输入验证码' : 'Please enter verification'
              "
            />
            <el-button
              style="width: 130px"
              id="getCode"
              size="small"
              class="ml10"
              :disabled="codeIndex != 60"
              @click="getPwdCode"
              >{{ codeName }}</el-button
            >
          </el-form-item>
        </el-form>

        <el-button
          type="primary"
          @click="checkChangePwdCode('formvalue')"
          round
          style="width: 100%"
        >
          {{ lang_data == 1 ? "下一步" : "The next step" }}
        </el-button>
      </div>
      <div v-if="step == 2" class="modalCont">
        <el-form
          ref="formvalue1"
          @submit.native.prevent
          :model="formvalue1"
          :rules="Rules"
        >
          <div class="step">
            <div class="step1">
              <a class="act">{{
                lang_data == 1 ? "验证身份" : "Authenticate"
              }}</a
              ><a>{{
                lang_data == 1 ? "重置密码" : "To reset your password"
              }}</a>
            </div>
            <div class="step2">
              <a class="act">&nbsp;</a><a class="act">&nbsp;</a>
            </div>
          </div>

          <el-form-item label="" prop="password" class="mt20">
            <el-input
              v-model="formvalue1.password"
              type="password"
              size="small"
              :placeholder="
                lang_data == 1 ? '设置新密码' : 'Setting a New Password'
              "
            />
          </el-form-item>

          <el-form-item label="" prop="password1" class="flex">
            <el-input
              v-model="formvalue1.password1"
              type="password"
              size="small"
              :placeholder="
                lang_data == 1
                  ? '再次输入新密码'
                  : 'Enter the new password again'
              "
            />
          </el-form-item>
        </el-form>

        <el-button
          type="primary"
          round
          style="width: 100%"
          @click="changePwd('formvalue1')"
        >
          {{ lang_data == 1 ? "确认" : "confirm" }}
        </el-button>
      </div>
      <div v-if="step == 3" class="modalCont">
        <div class="moblieComplete">
          <el-result
            icon="success"
            :title="
              lang_data == 1 ? '重置密码成功！' : 'Password reset succeeded'
            "
            subTitle=""
          >
            <template slot="extra">
              <el-button type="primary" size="medium" @click="okEvent"
                >返回登录页面</el-button
              >
            </template>
          </el-result>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "moblie",
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      step: 1,
      wait: 0,
      radio: 1,
      codeIndex: 60,
      codeName: localStorage.lang_data == 1 ? "获取验证码" : "Access to verify",
      formvalue: {
        pwdCode: "",
      },
      formvalue1: {
        password: "",
        password1: "",
      },
      Rules: {
        pwdCode: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入验证码"
                : "Please enter verification",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入密码"
                : "Please enter your password",
            trigger: "blur",
          },
          {
            min: 6,
            message:
              localStorage.lang_data == 1
                ? "请输入至少6个字符"
                : "Please enter a minimum of 6 characters",
            trigger: "blur",
          },
        ],
        password1: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请再次输入密码"
                : "Please enter your password again",
            trigger: "blur",
          },
          {
            min: 6,
            message:
              localStorage.lang_data == 1
                ? "请输入至少6个字符"
                : "Please enter a minimum of 6 characters",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    doctor_info: [String, Object],
    pwdStatusShow: [Boolean],
  },
  created() {
    if (this.doctor_info.mobile) {
      return (this.radio = 1);
    } else if (this.doctor_info.email) {
      return (this.radio = 2);
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      if (!localStorage.token) {
        this.okEvent();
      }
    },
    okEvent() {
      this.$router.push("/login");
    },
    getPwdCode() {
      //获取验证码
      if (this.codeIndex == 60) {
        this.codeIndex--;
        this.$axios
          .get("/changepwdcode", {
            params: {
              account_type: this.radio,
            },
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              let time = setInterval(() => {
                this.codeName = "重新发送（" + this.codeIndex + "）";
                this.codeIndex--;
                if (this.codeIndex == 0) {
                  clearInterval(time);
                  this.codeName = "重新发送";
                  this.codeIndex = 60;
                }
              }, 1000);
            } else {
              this.$message.error(res.msg);
              this.codeIndex = 60;
            }
          });
      }
    },
    checkChangePwdCode(info) {
      //修改密码验证码确认
      this.$refs[info].validate((valid) => {
        if (valid) {
          var params = {
            smscode: this.formvalue.pwdCode,
            account_type: this.radio,
          };
          this.$axios
            .post("/ucenter/checkchangepwdcode", params)
            .then((res) => {
              if (res.code != 1) {
                this.$message.error(res.msg);
                return;
              }
              this.step++;
            });
        }
      });
    },
    changePwd(info) {
      //修改密码
      this.$refs[info].validate((valid) => {
        if (valid) {
          if (this.formvalue1.password != this.formvalue1.password1) {
            this.$message.warning(
              this.lang_data == 1
                ? "两次密码不一致"
                : "The two passwords are different"
            );
            return;
          }
          var params = {
            smscode: this.formvalue.pwdCode,
            password: this.formvalue1.password,
            password1: this.formvalue1.password1,
            account_type: this.radio,
          };
          this.$axios.post("/ucenter/changepwd", params).then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
              return;
            } else {
              this.step++;
              this.$message.success(res.msg);
              localStorage.removeItem("userData");
              this.$store.dispatch("clearLoginInfo");
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.mwCont {
  padding: 0px 50px 20px;
  overflow: hidden;
}
#getCode:disabled,
#getCode1:disabled {
  background: #eeeded;
  border-color: #eeeded;
  color: #b1b1b1;
}
.step {
  overflow: hidden;
  a {
    cursor: default;
    &:hover {
      cursor: default;
    }
  }
  width: 100%;
  .step1 {
    width: 100%;
    height: 30px;
    line-height: 30px;
    float: left;
    font-size: 13px;
    color: #333333;
    a {
      width: 50%;
      float: left;
      &:hover {
        text-decoration: none;
      }
      &:last-child {
        text-align: right;
      }
      &.act {
      }
    }
  }
  .step2 {
    width: calc(100% - 36px);
    height: 3px;
    background: #dbdbdb;
    float: left;
    position: relative;
    margin: 10px 18px 20px 18px;
    a {
      width: 50%;
      height: 3px;
      float: left;
      &:hover {
        text-decoration: none;
      }
      &:first-child {
        &:before {
          width: 10px;
          height: 10px;
          background: #999;
          content: "";
          top: -7px;
          left: -18px;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          border: 5px solid #e0e0e0;
        }
      }
      &:last-child {
        &:before {
          width: 10px;
          height: 10px;
          background: #999;
          content: "";
          top: -7px;
          right: -18px;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          border: 5px solid #e0e0e0;
        }
      }
      &.act {
        background: #009a83;
        &:before {
          background: #009a83;
          border-color: #c6c9f4;
        }
      }
    }
  }
}
.moblieComplete {
  width: 100%;
  height: 200px;
  float: left;
  background: url(/static/img/modifySuccess.png) no-repeat center 0;
  text-align: center;
  p {
    font-size: 14px;
    color: #888;
  }
  p:first-child {
    font-size: 18px;
    color: #333;
    line-height: 40px;
    margin-top: 90px;
    padding-left: 10px;
  }
  p:last-child {
    label {
      color: #333;
    }
  }
}
</style>
